import request from '@/utils/request'

export function getGeneBank(data) {
    return request({
      url: window.g.toolsCode + '/blast/getGeneBank',
      method: 'POST',
      data
    })
  }
  

  export function resultgetAll(data) {
    return request({
      url: window.g.toolsCode + '/jobResult/findList',
      method: 'get',
      params: data
    })
  }
  
  // 识别霉菌
export function getMould(data, config) {
  return request({
    url: window.g.toolsCode +'/api/getMould',
    method: 'post',
    ...config,
    data
  })
}

// 识别害虫
export function getPest(data, config) {
  return request({
    url: window.g.toolsCode +'/api/getPest',
    method: 'post',
    ...config,
    data
  })
}

export function queryCase(data) {
  return request({
    url:'/open/queryCase',
    method: 'get',
    params:data
  })
}
